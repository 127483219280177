import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  SECTION_DISPLAY_LOCATION_MAIN,
  SECTION_DISPLAY_LOCATION_SIDEBAR,
} from '../../../constants/pages';

import ResizeListener from '../../Listeners/ResizeListener';
import SectionComponent from '../SectionComponent';

import styles from '../style/LayoutTypeSidebarRight.module.scss';

export const LayoutTypeSidebarRight = (props) => {

  // Constants
  const sidebarBreak = 1024;

  // Props
  const {
    adminTheme,
    layout,
  } = props;

  // State
  const [sidebarVisible, setSidebarVisible] = useState(true);

  // Refs
  const layoutRef = useRef(null);

  // Methods
  const windowResize = useCallback((width, _) => {
    const showSideboard = width > sidebarBreak;
    setSidebarVisible(showSideboard);
  }, [ sidebarBreak, setSidebarVisible ]);

  const mainSections = () => {
    if(!layout.sections) { return []; }
    return layout.displaySections(sidebarVisible ? SECTION_DISPLAY_LOCATION_MAIN : null);
  }

  const sidebarSections = () => {
    if(!sidebarVisible || !layout.sections) { return []; }
    return layout.displaySections(SECTION_DISPLAY_LOCATION_SIDEBAR);
  }

  // Effects
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if(windowWidth) {
      windowResize(windowWidth);
    }
  }, [ windowResize ]);

  // Render
  return (
    <div className={styles.LayoutTypeSidebarRight} ref={layoutRef}>
      <ResizeListener onResize={windowResize} />
      <div className={styles.main}>
        {mainSections().map((section, i) => {
          return <div key={i} className={styles.mainSectionWrapper}>
            <SectionComponent
              adminTheme={adminTheme}
              section={section} />
          </div>;
        })}
      </div>
      <div className={styles.sidebar}>
        {sidebarSections().map((section, j) => {
          return <div key={j} className={styles.sidebarSectionWrapper}>
            <SectionComponent
              adminTheme={adminTheme}
              section={section} />
          </div>;
        })}
      </div>
    </div>
  );
};

export default LayoutTypeSidebarRight;




