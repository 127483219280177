

export const ANALYTICS_CONFIG_METRIC = 'anal-config-metric';
export const ANALYTICS_CONFIG_MODE = 'anal-config-mode';
export const ANALYTICS_CONFIG_SHOW_ALL = 'anal-config-show-all';
export const ANALYTICS_CONFIG_SHOW_TODAY = 'anal-config-show-today';

export const ANALYTICS_DURATION_UNIT_DAY = 'd';
export const ANALYTICS_DURATION_UNIT_WEEK = 'w';
export const ANALYTICS_DURATION_UNIT_MONTH = 'm';
export const ANALYTICS_DURATION_UNIT_YEAR = 'y';

export const ANALYTICS_METRIC_COUNT = 'anal-metric-count';
export const ANALYTICS_METRIC_COUNT_ITEMS = 'anal-metric-count-items';
export const ANALYTICS_METRIC_TOTAL = 'anal-metric-total'; // Total refers to currency

export const ANALYTICS_SALES_MODE_ORDERS = 'anal-orders';
export const ANALYTICS_SALES_MODE_PRODUCT_LINES = 'anal-product-lines';

export const ANALYTICS_DATA_DECIMAL_KEYS = [
	'total_sales',
];